import React from 'react'

import { ONE_DAY, SingleParticipantWithState, TIME_LIMIT } from '../../api/types'
import { FollowUp } from './FollowUp'

type Props = {
  subjects: SingleParticipantWithState[]
  setDrawerData: Function
}

export const FollowUps: React.FC<Props> = ({ subjects, setDrawerData }) => {
  // only show longer then 14 days
  const timeLimit = new Date().getTime() - TIME_LIMIT * ONE_DAY

  const items = subjects
    .filter((subject) => {
      const eventIndex = subject.events[0].eventData === 'tag' ? 1 : 0

      return subject.events[eventIndex].createdAt < timeLimit
    })
    .sort((a, b) => {
      const eventIndexa = a.events[0].eventData === 'tag' ? 1 : 0
      const eventIndexb = b.events[0].eventData === 'tag' ? 1 : 0
      return a.events[eventIndexa].createdAt - b.events[eventIndexb].createdAt
    })

  return (
    <div className="container-fluid mt-3" style={{ background: '#eaeaea' }}>
      <div className="container ">
        <div className="row mt-3 mb-2 " style={{}}>
          <div className="col-12 mt-4 py-3 d-flex ">
            <div className="mr-2" style={{ marginTop: '.4rem', background: '#212628', width: 15, height: 1 }}></div>
            <h6 className="font-weight-bold " style={{ color: '#212628', fontSize: '.85rem' }}>
              <em>{items.length} Participants needs followup</em>
            </h6>
          </div>
        </div>

        <div className="row mt-2" style={{}}>
          {items.map((item, index) => {
            const eventIndex = item.events[0].eventData === 'tag' ? 1 : 0
            const lastEvent = item.events[eventIndex].createdAt
            const showAttention = lastEvent < timeLimit
            return (
              <FollowUp
                key={item.id}
                setDrawerData={setDrawerData}
                subject={item}
                lastEvent={lastEvent}
                showAttention={showAttention}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
