import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'

import { SingleParticipantWithState } from '../../api/types'

type Props = {
  setDrawerData: Function
  showDrawer?: SingleParticipantWithState | null
  children: React.ReactNode
  title?: string
}

export const SubjectDrawer: React.FC<Props> = ({ showDrawer, setDrawerData, children, title }) => {
  // handle state change
  const [openDrawer, setOpen] = useState(false)

  // We want to keep the data showing while closing the drawer
  // that is why the showDrawer value can not e used as toggle indicator
  useEffect(() => {
    if (showDrawer) {
      setOpen(Boolean(showDrawer))
    }
  }, [showDrawer])

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={openDrawer}
      afterVisibleChange={(open) => {
        if (!open) {
          setDrawerData(null)
        }
      }}
      width={340}
      bodyStyle={{ padding: 0 }}
    >
      {children}
    </Drawer>
  )
}
