import React, { useRef } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { animated, useTransition } from 'react-spring'

type Props = {
  children: React.ReactNode
}

type LocationState = { index: number } | null | undefined

export const AnimatedRoute: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const state: LocationState = location.state as LocationState
  const oldIndex = useRef(0)

  const direction = state && state.index < oldIndex.current ? 200 : -200 // 100 //index < oldIndex.current ? 200 : -200

  oldIndex.current = state?.index ? state.index : 0

  //@ts-ignore
  const transitions = useTransition(location, (location: any) => location.pathname, {
    from: { opacity: 0, transform: `translate3d(${-direction}px,0px,0px)` },
    enter: { opacity: 1, transform: 'translate3d(0px,0px,0)' },
    leave: { opacity: 0, transform: `translate3d(${direction}px,0,0)` },
  })

  return (
    <div className="position-relative w-100">
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div key={key} className="position-absolute w-100 h-100" style={{ top: 0, left: 0, ...props }}>
            <Switch location={item}>{children}</Switch>
          </animated.div>
        )
      })}
    </div>
  )
}
