import React from 'react'
import hrt from 'human-readable-time'
import { Button, Tooltip } from 'antd'

import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { FOLLOW_UP, IN_PROGRESS, SingleParticipantWithState } from '../../api/types'
import { AttentionDot } from '../../components/Display/AttentionDot/AttentionDot'
import { getDisplayEvent } from '../../utilities/utils'

type Props = {
  lastEvent: number
  subject: SingleParticipantWithState
  setDrawerData: Function
  showAttention: boolean
}

export const FollowUp: React.FC<Props> = ({ lastEvent, subject, setDrawerData, showAttention }) => {
  const show = () => {
    setDrawerData(subject)
  }

  const labelSize = '.6rem'
  const titleSize = '1rem'

  return (
    <div className="col-12 mb-3">
      <div
        className="bg-white w-100 p-4 d-flex"
        style={{
          borderRadius: 3,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="pl-2 w-20">
          <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
            Name
          </p>
          <h5 className="mb-2 font-weight-bold" style={{ fontSize: titleSize }}>
            {subject.contactDetails?.name}
          </h5>
        </div>

        <div className="w-15">
          <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
            Last activity
          </p>
          {showAttention ? (
            <div className="d-flex">
              <h5 className="mb-0 text-danger font-weight-bold" style={{ fontSize: titleSize }}>
                {hrt(new Date(lastEvent), '%relative%')} ago
              </h5>
              <AttentionDot className="pl-1 " />
            </div>
          ) : (
            <div className="d-flex">
              <h5 className="mb-0 font-weight-bold" style={{ fontSize: titleSize }}>
                {hrt(new Date(lastEvent), '%relative%')} ago
              </h5>
            </div>
          )}
        </div>

        {/* STATUS */}
        <div className="w-15">
          <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
            Status
          </p>

          {!showAttention ? (
            <h5 className="mb-2 font-weight-bold text-muted" style={{ fontSize: titleSize }}>
              {IN_PROGRESS}
            </h5>
          ) : (
            <h5 className="mb-2 font-weight-bold" style={{ fontSize: titleSize }}>
              {FOLLOW_UP}
            </h5>
          )}
        </div>

        {/* STAGE */}
        <div className="w-25">
          <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
            Stage
          </p>
          <h5 className="mb-2 font-weight-bold text-capitalize" style={{ fontSize: titleSize }}>
            {getDisplayEvent(subject.state).toLowerCase()}
          </h5>
        </div>

        <div className="w-25 d-flex justify-content-end align-items-center">
          <div className="pr-4">
            <Tooltip className="use-pointer" title={'Call :' + subject.contactDetails?.phone}>
              <PhoneOutlined className="px-2" style={{ fontSize: '1.2rem' }} />
            </Tooltip>
            <Tooltip className="use-pointer" title={'Send email '}>
              <MailOutlined className="px-3" style={{ fontSize: '1.2rem' }} />
            </Tooltip>
          </div>
          <Button type="primary" onClick={show} className=" ">
            <p className="font-weight-bold text-white">UPDATE</p>
          </Button>
        </div>
      </div>
    </div>
  )
}
