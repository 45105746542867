import { SingleParticipantWithState } from '../api/types'
import { tagByEvent } from './sortParticipants'

type ActionTypes = 'INIT' | 'UPDATE' | 'UPDATE_STATE' | 'UPDATE_STATUS' | 'FEEDBACK'

export const subjectReducer = (state: SingleParticipantWithState[], action: { type: ActionTypes; payload: any }) => {
  if (action.type === 'INIT') {
    return tagByEvent({ subjects: action.payload })
  }
  if (action.type === 'UPDATE_STATE') {
    const selectedState = action.payload.state
    return state.map((item) => {
      if (item.id === action.payload.subjectId) {
        item.state = selectedState
        item.events.unshift(action.payload.subjectEvent)
      }
      return item
    })
  }

  if (action.type === 'UPDATE_STATUS') {
    return state.map((item) => {
      if (item.id === action.payload.subjectId) {
        item.events.unshift(action.payload.subjectEvent)
      }
      return item
    })
  }

  if (action.type === 'FEEDBACK') {
    return state.map((item) => {
      if (item.id === action.payload.subjectId) {
        item.feedback = action.payload.feedback
      }
      return item
    })
  }

  // when the mutation in the modal is completed
  if (action.type === 'UPDATE') {
    // The selected state for the user
    // this is currently random since the mock server returns a random event on every mutation
    const selectedState = action.payload.subjectEvent.eventType

    // update the selected state of the user
    // - again the actual data returned from the mock generates a new event with random data
    return state.map((item) => {
      if (item.id === action.payload.participantId) {
        item.state = selectedState
        item.events.unshift(action.payload.subjectEvent)
      }
      return item
    })
  }

  throw new Error('Action type did not match anything')
}
