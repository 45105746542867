import React from 'react'
import { animated, useTransition } from 'react-spring'

import { DropMenuItem, Events, SingleParticipantWithState } from '../../api/types'
import { NewReferral } from './NewReferral'
//import useResizeAware from 'react-resize-aware'

type Props = {
  setDrawerData: Function
  setDisqulifyData: Function
  subjects: SingleParticipantWithState[]
  dispatch: Function
}

export const NewReferrals: React.FC<Props> = ({ subjects, setDrawerData, dispatch, setDisqulifyData }) => {
  const items = subjects.filter((item) => item.state === Events.SIGNED_UP)

  const statusChange = (subjectId: string, selectedState: DropMenuItem) => {
    dispatch({
      type: 'UPDATE_STATE',
      payload: { subjectId: subjectId, state: selectedState.type },
    })
  }

  const show = (subject: SingleParticipantWithState) => {
    setDrawerData(subject)
  }
  const showDisqualify = (subject: SingleParticipantWithState) => {
    setDisqulifyData(subject)
  }

  const transitions = useTransition(items, (item) => item.id, {
    from: { opacity: 0, width: 380 },
    enter: { opacity: 1, width: 380 },
    leave: { opacity: 0, width: 0 },
  })

  return (
    <div className="container">
      {items.length > 0 ? (
        <>
          <div className="row mt-3 mb-1 " style={{}}>
            <div className="col-12 mb-2 d-flex ">
              <div className="mr-2" style={{ marginTop: '.4rem', background: '#212628', width: 15, height: 1 }}></div>

              <h6 className="font-weight-bold" style={{ color: '#212628', fontSize: '.85rem' }}>
                <em>{items.length} New referals</em>
              </h6>
            </div>
          </div>

          <div className="row flex-nowrap " style={{ height: '19rem' }}>
            <div className=" d-flex flex-nowrap">
              {transitions.map(({ item, props, key }) => {
                return (
                  <animated.div key={key} className="position-relative overflow-hidden" style={props}>
                    <NewReferral
                      show={show}
                      showDisqualify={showDisqualify}
                      key={item.id}
                      statusChange={statusChange}
                      subject={item}
                    />
                  </animated.div>
                )
              })}
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
