import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Layout, Menu } from 'antd'
import React from 'react'
import logoUrl from '../../images/logo-SandMe.svg'
import { authStore } from '../../store/auth/authStore'
import * as theme from '../../theme'

type Props = {
  trialName: string | undefined
}

export const Header: React.FC<Props> = ({ trialName }) => {
  const logout = authStore((state) => state.logout)
  const username = authStore((state) => state.user.username)

  const onLogout = () => {
    logout()
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={onLogout}>Log out</div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header className="d-flex justify-content-between bg-white">
      <div className="d-flex h-100 align-items-baseline">
        <img style={{ height: `${theme.SIZE_BASE_LOGO}px` }} src={logoUrl} alt="Studies&amp;Me logo" />
        <p className="mb-0 pl-4">Trial: {trialName}</p>
      </div>
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" href="./">
          {username} <DownOutlined />
        </a>
      </Dropdown>
    </Layout.Header>
  )
}
