import React, { useEffect, useState } from 'react'
import { EventType, ONE_DAY, SingleParticipantWithState, stageArr, statusArr, TIME_LIMIT } from '../../api/types'
import { Button, Menu, Popconfirm, Steps, Tooltip } from 'antd'
import get from 'lodash.get'
import { StageDropdown } from '../Display/StageDropdown'
import { MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { ContactHistory } from './ContactHistory'
import { AttentionDot } from '../Display/AttentionDot/AttentionDot'
import hrt from 'human-readable-time'
import './subject-details.scss'
import ArrowSvg from '../../images/svg/dropdown-arrow.svg'
import { COLOR_PRIMARY } from '../../theme'

type Props = {
  setDisqulifyData: Function
  showDrawer?: SingleParticipantWithState | null
  dispatch: Function
}

export const SubjectDetails: React.FC<Props> = ({
  showDrawer,

  dispatch,
  setDisqulifyData,
}) => {
  const [status, setStatus] = useState('Add Status')

  useEffect(() => {
    if (!showDrawer) {
      setStatus('Add Status')
    }
  }, [showDrawer])

  // When a status is clicked
  const onStatusChange = (e: any) => {
    const selectedStatus = statusArr[e.key]
    const now = new Date().getTime()

    setStatus('Status was added')

    dispatch({
      type: 'UPDATE_STATUS',
      payload: {
        subjectId: showDrawer?.id,
        state: selectedStatus.type,
        subjectEvent: {
          id: now.toString(),
          eventData: '',
          eventType: selectedStatus.type,
          createdBy: 'You',
          createdAt: now,
        },
      },
    })
  }

  const onStageChangeReverse = () => {
    if (selectedStateIndex < 1) {
      alert('We can not moved the user to previous step')
      return
    }
    onStageChange(-1)
  }

  const onStageChangeForward = () => {
    console.log(' SubjectDetails > gio = ')
    onStageChange(1)
  }

  const onStageChange = (direction: number) => {
    const newIndex = selectedStateIndex + direction
    const validNextStep = newIndex < stageArr.length
    console.log(' SubjectDetails > validNextStep = ', validNextStep, newIndex, selectedStateIndex)
    if (!validNextStep) {
      alert('User can not be moved beyound randomized')
      return
    }

    const selectedState = stageArr[newIndex]

    const now = new Date().getTime()
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        subjectId: showDrawer?.id,
        state: selectedState.type,
        subjectEvent: {
          id: now,
          eventData: '',
          eventType: selectedState.type,
          createdBy: 'You',
          createdAt: now,
        },
      },
    })
  }

  const showDisqualify = () => {
    setDisqulifyData(showDrawer)
  }

  const statusOptions = (
    <Menu onClick={onStatusChange}>
      {statusArr.map((item, index) => {
        return (
          <Menu.Item key={index}>
            <p className="mb-0 text-dark">{item.title}</p>
          </Menu.Item>
        )
      })}
    </Menu>
  )

  //const id = get(showDrawer, 'id', '')
  const state: EventType | string = get(showDrawer, 'state', '')

  const name = get(showDrawer, 'contactDetails.name', '')
  const email = get(showDrawer, 'contactDetails.email', '')
  const phone = get(showDrawer, 'contactDetails.phone', '')

  const events = get(showDrawer, 'events', [])
  const eventIndex = events.length > 0 && events[0].eventData === 'tag' ? 1 : 0
  const lastEvent = events.length > 0 && events[eventIndex].createdAt

  const selectedStateIndex = stageArr
    .map((item) => {
      return item.type as string
    })
    .indexOf(state)

  const showAttention = lastEvent < new Date().getTime() - ONE_DAY * TIME_LIMIT ? true : false

  return (
    <div className="container h-100 subject-details">
      <div className="h-100 d-flex flex-column ">
        <div
          className="row px-0 py-4 px-2"
          style={{
            background: '#F0F0F0',
            borderBottom: '1px solid #D3D3D3',
            borderTop: '1px solid #D3D3D3',
          }}
        >
          <div className="col-12">
            <h4 className="mb-3 font-weight-bold">{name}</h4>

            <Tooltip className="use-pointer" placement="topLeft" title="Click to send email">
              <div className="d-flex">
                <MailOutlined className="text-muted" style={{ fontSize: '1.1rem' }} />
                <h5 className="pl-3">
                  <small className="text-muted">{email.toLowerCase()}</small>
                </h5>
              </div>
            </Tooltip>

            <div className="d-flex mt-1">
              <PhoneOutlined className="text-muted" style={{ fontSize: '1.2rem' }} />
              <h5 className="pl-2">
                <small className="text-muted">{phone}</small>
              </h5>
            </div>
          </div>

          {/*<div className="col-12 mt-2">
            <StageDropdown menu={menu} initialValue={getDisplayEvent(state)} />
          </div>*/}
        </div>

        <div className="row px-2" style={{ borderBottom: '1px solid #D3D3D3' }}>
          <div className="col-12 py-4 mb-1">
            <div className="d-flex">
              <h5 className="mb-1  text-primary d-flex">
                Last activity
                <span className="pl-1 pr-1 font-weight-bold">{hrt(new Date(lastEvent), '%relative%') + ' '}</span>
                ago
              </h5>

              {showAttention && <AttentionDot className="pl-1 " />}
            </div>

            <h6 className="mb-3 text-muted">Please update the user status</h6>
            <StageDropdown menu={statusOptions} initialValue={status} background="bg-secondary" />
          </div>
        </div>

        <div className="row" style={{ borderBottom: '1px solid #D3D3D3' }}>
          <div className="col-12 mt-4">
            <h6 className="font-weight-bold mb-3">Steps</h6>
            <Steps direction="vertical" size="small" current={selectedStateIndex + 1}>
              <Steps.Step title={'New referal'} description="" />
              {stageArr.map((item, index) => {
                return <Steps.Step key={index} title={item.title} description="" />
              })}
            </Steps>
          </div>
          <div className="col-12 mb-3">
            <div
              onClick={onStageChangeForward}
              className="d-flex justify-content-between drop-shadow use-pointer"
              style={{
                borderRadius: 2,
                paddingTop: '.4rem',
                paddingBottom: '.4rem',
                paddingRight: '1rem',
                paddingLeft: '1rem',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                background: COLOR_PRIMARY,
              }}
            >
              <p className="text-white mb-0 " style={{ fontSize: '.7rem', paddingTop: '.1rem' }}>
                MOVE TO <span className="font-weight-bold">NEXT STEP</span>
              </p>

              <div style={{ width: '1rem', transform: 'rotate(-90deg)' }}>
                <img className="img-fluid" src={ArrowSvg} alt="" />
              </div>
            </div>

            {selectedStateIndex > 0 ? (
              <Popconfirm
                title="Move user to the previous step?"
                onConfirm={onStageChangeReverse}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <p className="mt-2 text-primary" style={{ fontSize: '.75rem' }}>
                  Made a mistake -
                  <span className="text-muted use-pointer">
                    <u> move patient to a previous step</u>
                  </span>
                </p>
              </Popconfirm>
            ) : null}
          </div>
        </div>

        <div className="row px-0 d-flex flex-grow-1 " style={{}}>
          <div className="col-12 px-0 h-100 d-flex flex-column">
            <ContactHistory events={events} />
            <div
              className="px-3 py-2 d-flex justify-content-between align-items-center position-relative"
              style={{ background: '#F0F0F0', zIndex: 10, borderTop: '1px solid #D3D3D3' }}
            >
              <h6 className="text-muted mt-1">Disqualify participant </h6>
              <Button className="bg-danger " onClick={showDisqualify}>
                <p className="text-white font-weight-bold mb-0 " style={{ borderRadius: 4, fontSize: '.65rem' }}>
                  DISQUALIFY
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
