import React from 'react'
import { SingleParticipantWithState } from '../../api/types'
import { Button } from 'antd'

type Props = {
  subject: SingleParticipantWithState
  setDrawerData: Function
  setDisqulifyData: Function
}

export const DisqualifiedItem: React.FC<Props> = ({ subject, setDrawerData, setDisqulifyData }) => {
  const show = () => {
    setDrawerData(subject)
    setDisqulifyData(subject)
  }

  const labelSize = '.6rem'
  const titleSize = '1rem'

  return (
    <div className="col-12 mb-3">
      <div
        className="bg-white w-100 p-4 d-flex"
        style={{
          borderRadius: 3,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* NAME */}
        <div className="pl-2 w-20 d-flex align-items-center">
          <div>
            <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
              Name
            </p>
            <h5 className="mb-2 font-weight-bold" style={{ fontSize: titleSize }}>
              {subject.contactDetails?.name}
            </h5>
          </div>
        </div>

        {/* STATUS */}
        <div className="w-25 d-flex align-items-center  ">
          <div>
            <p className="mb-1 font-weight-bold" style={{ fontSize: labelSize }}>
              Cause
            </p>

            <h5 className="mb-2 font-weight-bold" style={{ fontSize: titleSize }}>
              {subject.events[0].eventData}
            </h5>
          </div>
        </div>

        <div className="w-35">
          <p className="font-weight-bold" style={{ fontSize: labelSize, marginBottom: '.1rem' }}>
            Feedback
          </p>

          <div className="d-flex flex-wrap">
            {subject.feedback.length !== 0 ? (
              subject.feedback.map((item, index) => {
                return (
                  <div className="rounded-pill mr-1 mb-1 no-select" style={{ background: '#f6bb00' }}>
                    <p
                      className="mb-0 text-white font-weight-bold text-uppercase "
                      style={{ fontSize: '.7rem', padding: '2px 10px 2px 10px' }}
                    >
                      <small>{item}</small>
                    </p>
                  </div>
                )
              })
            ) : (
              <h6
                onClick={show}
                className="mb-2 font-weight-bold pt-1 text-muted use-pointer"
                style={{ textDecoration: 'underline' }}
              >
                Add behavioral feedback
              </h6>
            )}
          </div>
        </div>

        <div className="w-20 d-flex justify-content-end align-items-center">
          <Button type="primary" onClick={show}>
            <p className="font-weight-bold text-white">Add {subject.feedback.length === 0 ? '' : 'more'} Feedback</p>
          </Button>
        </div>
      </div>
    </div>
  )
}
