import React, { useState } from 'react'

import { ONE_DAY, SingleParticipantWithState, tabs, TIME_LIMIT } from '../../api/types'
import { WizardWrapper } from '../../components/Navigators/WizardWrapper'
import { FollowUp } from '../Overview/FollowUp'
import { COLOR_PRIMARY } from '../../theme'

type Props = {
  subjects: SingleParticipantWithState[]
  setDrawerData: Function
}

export const Participants: React.FC<Props> = ({ subjects, setDrawerData }) => {
  const [pageIndex, setPageIndex] = useState(0)

  // tab bar click
  const navigate = (index: number) => {
    setPageIndex(index)
  }

  const now = new Date().getTime()

  return (
    <div className="container mt-5">
      <div className="row" style={{}}>
        <div className="col-12">
          <div className="d-flex justify-content-around" style={{ borderBottom: `1px solid #B5C7CF` }}>
            {tabs.map((tab, index) => {
              return (
                <div
                  className="use-pointer no-select text-primary w-33"
                  style={{
                    borderBottom: pageIndex === index ? `3px solid ${COLOR_PRIMARY}` : `0px solid ${COLOR_PRIMARY}`,
                  }}
                  key={index}
                  onClick={() => {
                    navigate(index)
                  }}
                >
                  <div className="px-4 text-center">
                    <p
                      className="font-weight-bold"
                      style={{
                        color: pageIndex === index ? '#08455f' : '#7b7b7b',
                        fontSize: '.9rem',
                      }}
                    >
                      {tab.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="row mt-3" style={{}}>
        <WizardWrapper pageIndex={pageIndex}>
          {subjects
            .filter((subject) => {
              return subject.state === tabs[pageIndex].filterKey
            })
            .sort((a, b) => {
              const eventIndexa = a.events[0].eventData === 'tag' ? 1 : 0
              const eventIndexb = b.events[0].eventData === 'tag' ? 1 : 0
              return a.events[eventIndexa].createdAt - b.events[eventIndexb].createdAt
            })
            .map((item, index) => {
              const eventIndex = item.events[0].eventData === 'tag' ? 1 : 0
              const lastEvent = item.events[eventIndex].createdAt
              const showAttention = lastEvent < now - ONE_DAY * TIME_LIMIT
              return (
                <div key={index}>
                  <FollowUp
                    key={item.id}
                    lastEvent={lastEvent}
                    subject={item}
                    setDrawerData={setDrawerData}
                    showAttention={showAttention}
                  />
                </div>
              )
            })}
        </WizardWrapper>
      </div>
    </div>
  )
}
