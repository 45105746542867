import {
  Events,
  EventType,
  eventTypeArr,
  FOLLOW_UP,
  ONE_DAY,
  SingleParticipantWithState,
  TIME_LIMIT,
} from '../api/types'
import random from 'lodash.random'
import { getTrialDataQuery_trial_subjects_events } from '../api/__generated__/getTrialDataQuery'

interface IParams {
  daysFromNow: number
  hours: number
}

const generateDateTime = ({ daysFromNow, hours }: IParams): Date => {
  const today = new Date()
  const notToday = new Date().setDate(today.getDate() + daysFromNow) // lol
  return new Date(new Date(notToday).setHours(hours, 0, 0, 0))
}

type Props = {
  subjects: SingleParticipantWithState[] | undefined
}

// should be unit tested,
// Test that the state is set correctly and the sort works as expected
export const tagByEvent = ({ subjects }: Props) => {
  if (!subjects) {
    return []
  }

  subjects.forEach((subject) => {
    // when the array is sorted - we can infer that index 0 - is the latest event
    const startDay = random(3, 10)
    let prev = 0
    subject.events.forEach((event, index) => {
      if (event.eventType === Events.SIGNED_UP) {
        event.createdAt = generateDateTime({
          daysFromNow: -random(0, 1),
          hours: random(0, 8),
        }).getTime()
        return event
      }

      // handle showing new referal which has passed time limit
      if (event.id === '00000000000-1') {
        event.createdAt = generateDateTime({
          daysFromNow: -3,
          hours: random(0, 12),
        }).getTime()
        return event
      }

      const rnd = index === 0 ? 0 : random(startDay + 1, prev + 4)
      prev = rnd + 1
      const t = generateDateTime({ daysFromNow: -(startDay + rnd), hours: 0 })
      event.createdAt = t.getTime()
      return event
    })

    //subject.events.sort((a, b) => b.createdAt - a.createdAt)

    // find the latest stage event and set the state
    let type: string = ''
    const len = subject.events.length
    for (let i = 0; i < len; i++) {
      type = subject.events[i].eventType
      if (eventTypeArr.indexOf(type) !== -1) {
        break
      }
    }

    // set the state if the subject
    subject['state'] = type as EventType

    const time = new Date().getTime()
    const d = time - TIME_LIMIT * ONE_DAY
    const latestEvent = subject.events[0]
    const isFollowUp = latestEvent.createdAt < d

    const followUpEvent: getTrialDataQuery_trial_subjects_events = {
      id: time.toString() + '-000111',
      eventData: 'tag',
      eventType: FOLLOW_UP,
      createdBy: 'Studies & me',
      createdAt: latestEvent.createdAt + ONE_DAY * TIME_LIMIT,
      __typename: 'subjectEvent',
    }

    // add followup event to hitory
    if (isFollowUp && latestEvent.eventType !== Events.SIGNED_UP && latestEvent.eventType !== Events.LEFT_TRIAL) {
      subject.events.unshift(followUpEvent)
    }

    return subject
  })

  return subjects
}
