import React from 'react'
import { Dropdown } from 'antd'
import ArrowSvg from '../../images/svg/dropdown-arrow.svg'

type Props = {
  menu: JSX.Element
  initialValue?: string
  background?: 'bg-primary' | 'bg-secondary'
}

export const StageDropdown: React.FC<Props> = ({ menu, initialValue = 'test', background = 'bg-primary' }) => {
  return (
    <Dropdown trigger={['click']} placement="bottomCenter" overlay={menu}>
      <div
        className={`${background} d-flex justify-content-between drop-shadow use-pointer`}
        style={{
          borderRadius: 2,
          paddingTop: '.4rem',
          paddingBottom: '.4rem',
          paddingRight: '1rem',
          paddingLeft: '1rem',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <p className="text-white mb-0 font-weight-bold " style={{ fontSize: '.7rem', paddingTop: '.1rem' }}>
          {initialValue}
        </p>

        <div style={{ width: '1rem' }}>
          <img className="img-fluid" src={ArrowSvg} alt="" />
        </div>
      </div>
    </Dropdown>
  )
}
