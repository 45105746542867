export const COLOR_PRIMARY = '#08455F'
export const COLOR_SECONDARY = '#B5C7CF'
export const COLOR_LIGHT = '#FFFFFF'
export const COLOR_ATTENTION = '#FFB904'
export const COLOR_CONTENT_BACKGROUND = '#f3f6f7'

export const SIZE_BASE = 8
export const SIZE_BASE_LOGO = SIZE_BASE * 2.5

export const COLOR_TEXT_PRIMARY = COLOR_PRIMARY
export const COLOR_TEXT_LIGHT = COLOR_LIGHT
export const COLOR_BUTTON = COLOR_ATTENTION

export const BACKGROUND_OVERLAY = 'rgba(8, 69, 95, 0.95)'

export const FONT_SIZE_TEXT_SMALL = '12px'
export const FONT_SIZE_TEXT = '14px'
export const FONT_SIZE_TEXT_MEDIUM = '16px'
export const FONT_SIZE_TEXT_BIG = '1.8rem'
export const FONT_SIZE_TEXT_LARGE = '3rem'
