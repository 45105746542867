import { Events } from './types'

const studiesAndMe = 'Studies&Me'

export const data = {
  trial: {
    subjects: [
      {
        id: 'fe125c25-285c-424a-9d9e-651212ac551b',
        feedback: [],
        contactDetails: {
          name: 'Stan Ernser',
          email: 'Elody_Osinski54@example.org',
          phone: '+45 5105 6091',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: studiesAndMe,
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '33421f37-e077-4180-b9f7-339d149b219d',
        feedback: [],
        contactDetails: {
          name: 'Ephraim Moen',
          email: 'Gia32@example.net',
          phone: '+45 6782 0403',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '61bece10-48e3-4eb9-a1fb-7fe951d54749',
        feedback: [],
        contactDetails: {
          name: 'Alek Lakin',
          email: 'Marguerite.Gottlieb@example.net',
          phone: '+45 4368 4374',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.RESCHEDULED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '9be0000-eb50-4251-8185-a8d4814758ee',
        feedback: [],
        contactDetails: {
          name: 'Jens Peter Hansen',
          email: 'jens.peter@example.com',
          phone: '+45 3088 4779',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '9bef12e9-eb50-4251-8185-a8d4814758ee',
        feedback: [],
        contactDetails: {
          name: 'Marco Pfeffer',
          email: 'Elnora.Frami35@example.com',
          phone: '+45 3088 4779',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '00000000000-1',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '6b795342-9e82-4a74-bfdf-697612832255',
        feedback: [],
        contactDetails: {
          name: 'Alicia Hegmann',
          email: 'Kale49@example.com',
          phone: '+45 9510 2616',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '708ecc42-9035-4a3c-aa60-3e70d68e1691',
        feedback: [],
        contactDetails: {
          name: 'Zachariah Daniel',
          email: 'Alene.Zieme22@example.com',
          phone: '+45 1943 2468',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: 'a10154c2-639c-405d-8ef6-b2a10d34525b',
        feedback: [],
        contactDetails: {
          name: 'Rodrick Franecki',
          email: 'Mercedes_Tromp@example.net',
          phone: '+45 4628 4816',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.RESCHEDULED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '73f66e7f-857c-4c00-82c1-8d2f7bd45171',
        feedback: [],
        contactDetails: {
          name: 'Alda Walsh',
          email: 'Alison_Torphy80@example.org',
          phone: '+45 6863 9955',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.RESCHEDULED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '38a27600-a0c2-4719-b403-59871f77ab42',
        feedback: [],
        contactDetails: {
          name: 'General Borer',
          email: 'Harold.Bradtke@example.org',
          phone: '+45 5448 3669',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.RESCHEDULED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '5a99a404-0cfd-4e61-ad0e-4c4a6f7ecff3',
        feedback: [],
        contactDetails: {
          name: 'Miguel Dickens',
          email: 'Maxime_Pouros93@example.org',
          phone: '+45 8795 6066',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.NO_SHOW,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: 'e57c0fa9-be18-4fc8-b0c4-83990b5172dc',
        feedback: [],
        contactDetails: {
          name: 'Brady Medhurst',
          email: 'Jamison71@example.net',
          phone: '+45 4474 9538',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_RANDOMISATION,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '54144',
            eventData: '',
            eventType: Events.RESCHEDULED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '03fa41db-8e4a-4782-8138-3b335760c62c',
        feedback: [],
        contactDetails: {
          name: 'Christiana Mills',
          email: 'Lucius.Kuhn85@example.net',
          phone: '+45 0225 2986',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.NO_SHOW,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },

      {
        id: '3e393730-9369-410d-9f32-6e4464aca0b0',
        feedback: [],
        contactDetails: {
          name: 'Teresa Goyette',
          email: 'Mallory46@example.org',
          phone: '+45 6345 4825',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: 'Misdiagnosis condition',
            eventType: Events.LEFT_TRIAL,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: 'b36efbbe-9952-4d34-8342-9e84bc9e7828',
        feedback: [],
        contactDetails: {
          name: 'Reggie Swift',
          email: 'Mack23@example.com',
          phone: '+45 9275 9784',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '237d33e2-4371-42a1-af5e-df3ad099024a',
        feedback: [],
        contactDetails: {
          name: 'Jerry Corkery',
          email: 'Margot_Metz@example.net',
          phone: '+45 4426 9234',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.NO_SHOW,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: 'e69a7003-b4a9-42c9-8d2b-117c2ba6a8eb',
        feedback: [],
        contactDetails: {
          name: 'Estefania Fahey',
          email: 'Stacey93@example.org',
          phone: '+45 3157 8240',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_RANDOMISATION,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: 'd9585003-ae43-46c0-a5fa-1afaed841e29',
        feedback: [],
        contactDetails: {
          name: 'Kiarra Doyle',
          email: 'Myrl.Predovic@example.net',
          phone: '+45 8515 0408',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '54144',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_RANDOMISATION,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0122-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.SCHEDULED_FOR_SCREENING,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '01fa68d6-28e7-4100-b7d8-00032e9cbc35',
        feedback: [],
        contactDetails: {
          name: 'Antoinette Durgan',
          email: 'Lola.Sipes92@example.net',
          phone: '+45 4535 4678',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0155-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
      {
        id: '66b28d2c-f132-4c06-91b2-e10d38857063',
        feedback: [],
        contactDetails: {
          name: 'Mandy Jast',
          email: 'Janessa_Sporer@example.org',
          phone: '+45 1407 3249',
          __typename: 'subjectContactDetails',
        },
        events: [
          {
            id: '0144-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: Events.NOT_REACHED,
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '0101-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'REVIEWING_INFORMATION',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
          {
            id: '011f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'NOT_REACHED',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },

          {
            id: 'be1f8919-399e-4b3e-bf59-2d56417b282a',
            eventData: '',
            eventType: 'SIGNED_UP',
            createdBy: 'Dr. Cartwright',
            createdAt: 1582142642256,
            __typename: 'subjectEvent',
          },
        ],
        __typename: 'subject',
      },
    ],
    __typename: 'trial',
  },
}
