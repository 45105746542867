import { Button } from 'antd'
import React from 'react'
import { ONE_DAY, SingleParticipantWithState } from '../../api/types'
import { AttentionDot } from '../../components/Display/AttentionDot/AttentionDot'

type Props = {
  subject: SingleParticipantWithState
  statusChange: Function
  show: Function
  showDisqualify: Function
}

export const NewReferral: React.FC<Props> = ({ subject, statusChange, show, showDisqualify }) => {
  const showItem = () => {
    show(subject)
  }
  const showDisqualifyItem = () => {
    show(subject)
    showDisqualify(subject)
  }

  const t = subject.events[0].createdAt
  const days = 2 - Math.floor((new Date().getTime() - t) / ONE_DAY)
  const timeToUpdate = days
  const hoursToUpdate = 48 - Math.floor((new Date().getTime() - t) / (60 * 60 * 1000))

  const name = subject.contactDetails?.name?.toUpperCase() || ' '
  const firstLastName = name.split(' ')
  const initials = firstLastName[0][0] + firstLastName[1][0]

  return (
    <div className="px-3">
      <div
        className="w-100 bg-white p-3 d-flex flex-column align-items-center position-relative"
        style={{
          borderRadius: 3,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="d-flex w-100 justify-content-end">
          {timeToUpdate > 0 ? (
            <p className="mb-0 font-weight-bold text-primary mt-n1 mb-1 " style={{ fontSize: '.65rem' }}>
              {hoursToUpdate} HOURS TO UPDATE
            </p>
          ) : (
            <>
              <p className="d-flex mb-0 font-weight-bold text-danger mt-n1  " style={{ fontSize: '.65rem' }}>
                UPDATE NOW
              </p>
              <AttentionDot className="pl-1 mt-n1" h={16} w={5} />
            </>
          )}
        </div>
        <div
          className="rounded-circle bg-primary d-flex justify-content-center align-items-center "
          style={{ width: 80, height: 80 }}
        >
          <h2 className="text-white font-weight-bold mb-0">{initials}</h2>
        </div>
        <div className="pt-3 text-center">
          <h4 className="mb-1 font-weight-bold">{subject.contactDetails?.name}</h4>
          <p className="mb-0">{subject.contactDetails?.email}</p>
          <p className="mb-3">{subject.contactDetails?.phone}</p>
        </div>
        <div className="d-flex justify-content-start justify-content-lg-between w-100">
          <Button onClick={showDisqualifyItem} type="link" className="pl-0 ">
            <p className="font-weight-bold" style={{ fontSize: '.8rem' }}>
              DISQUALIFY
            </p>
          </Button>

          <Button type="primary" onClick={showItem}>
            <p className="text-white font-weight-bold" style={{ borderRadius: 4, fontSize: '.8rem' }}>
              UPDATE
            </p>
          </Button>
        </div>
      </div>
    </div>
  )
}
