import React, { ChangeEvent, useState } from 'react'
import { AutoComplete } from 'antd'
import { SingleParticipantWithState } from '../../api/types'
import get from 'lodash.get'

import { SearchOutlined } from '@ant-design/icons'
import { getDisplayEvent } from '../../utilities/utils'

interface ISearchBox {
  subjects: SingleParticipantWithState[]
  setDrawerData: Function
}

export const SearchBox: React.FC<ISearchBox> = ({ subjects, setDrawerData }) => {
  const [options, setOptions] = useState<React.ReactNode[]>([])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    const str = value.toLowerCase()
    setOptions(
      subjects
        .filter((item) => {
          const v =
            item.contactDetails && item.contactDetails?.name
              ? item.contactDetails?.name.toLowerCase().indexOf(str) > -1
              : false // > -1

          return v
        })
        .map((item, index) => {
          const name = get(item, 'contactDetails.name', '')
          const state = item.state
          return {
            value: name,
            subject: item,
            label: (
              <div className="d-flex ">
                <p className="mb-0 font-weight-bold text-black">{name}</p>
                <p className="mb-0 pl-1 text-capitalize text-muted">{getDisplayEvent(state).toLowerCase()}</p>
              </div>
            ),
          }
        })
    )
  }

  const onSelect = (value: string, selectedItem: any) => {
    const subject = selectedItem.subject

    setDrawerData(subject)
  }

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-8 mx-auto mt-3 mb-2">
          <div
            className="w-100 px-4 d-flex"
            style={{
              background: 'white',
              borderRadius: 40,
              paddingTop: 11,
              paddingBottom: 11,
              boxShadow: '0px 14px 30px -4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <AutoComplete
              style={{
                width: '100%',
              }}
              onSelect={onSelect}
              options={options as any}
            >
              <input
                type="text"
                className=" w-80 "
                placeholder="Search for a user..."
                style={{
                  fontStyle: 'italic',
                  color: '#6F6F6F',
                  border: 'none',
                  outline: 'none',
                  background: 'transparent',
                }}
                onChange={handleSearch}
              />
            </AutoComplete>
            <div className="">
              <SearchOutlined style={{ fontSize: '1.3rem' }} />
            </div>
          </div>

          {/*<div className="search-box d-flex h-100 justify-content-center align-items-center">
            <div
              className="d-flex justify-content-around w-100 py-3 "
              style={{
                background: 'white',
                borderRadius: 40,
                boxShadow: '0px 14px 133px 0px rgba(0, 0, 0, 0.20)',
              }}
            >
              <div className="pl-3 mr-2 my-auto">
                <SearchOutlined style={{ fontSize: '1.2rem' }} />
              </div>

              <input
                type="text"
                className="w-90"
                placeholder="Search for a user..."
                style={{
                  border: 'none',
                  outline: 'none',
                }}
                onChange={onChange}
                value={inputTxt}
                onKeyPress={enterPress}
              />
            </div>
          </div>
            */}
        </div>
      </div>
    </div>
  )
}
