import React from 'react'
import chart from '../../images/svg/chart.svg'
type Props = {}

export const Banner: React.FC<Props> = () => {
  return (
    <div className="container ">
      <div className="row mt-5 pb-4" style={{}}>
        <div className="col-6 ">
          <div className="py-4 pl-3 bg-primary position-relative" style={{ borderRadius: 2 }}>
            <p className="mb-0 font-weight-bold text-white" style={{ fontSize: '.7rem' }}>
              THIS WEEK
            </p>
            <h4 className="mb-0 text-white">VALUABLE KPI</h4>
            <div
              className="d-none d-lg-block position-absolute h-100 w-50 pr-2 mr-lg-4 pt-4"
              style={{ top: 0, right: 0 }}
            >
              <img className="img-fluid" src={chart} alt="" />
            </div>
          </div>
        </div>

        <div className="col-6 text-white">
          <div className="py-4 pl-3 bg-info " style={{ borderRadius: 2 }}>
            <h4 className="mb-0 text-white">VALUABLE KPI</h4>
            <p className="mb-0 font-weight-bold " style={{ fontSize: '.7rem' }}>
              Lorem ipsum dolor
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
