import React, { useEffect, useState } from 'react'
import { StageDropdown } from '../Display/StageDropdown'
import { Menu } from 'antd'
import {
  DropMenuItem,
  medicalDisqualification,
  motivationDisqualification,
  SingleParticipantWithState,
  TagsArr,
} from '../../api/types'

type TagProps = {
  click: Function
  item: { title: string; active: boolean }
  index: number
}

const Tag: React.FC<TagProps> = ({ click, item, index }) => {
  const tagClick = () => {
    click(index)
  }
  return (
    <div
      onClick={tagClick}
      className="rounded-pill mr-1 my-1 use-pointer no-select"
      style={{ background: item.active ? '#ffc107' : '#b4b4b4' }}
    >
      <p
        className="mb-0 text-white font-weight-bold text-uppercase "
        style={{ fontSize: '.7rem', padding: '7px 14px 7px 14px' }}
      >
        {item.title}
      </p>
    </div>
  )
}

type Props = {
  setDrawerData: Function
  showDrawer?: SingleParticipantWithState | null
  dispatch: Function
}

type StateProps = { title: string; type: string }

export const DisqulifyDrawer: React.FC<Props> = ({ showDrawer, setDrawerData, dispatch }) => {
  const motivationTxt = 'Select Motivational disqualification'
  const medicalTxt = 'Select Medical disqualification'

  const [medicalStatus, setMediicalStatus] = useState<StateProps>({
    title: medicalTxt,
    type: '',
  })

  const [motivationalStatus, setMotivationalStatus] = useState<StateProps>({
    title: motivationTxt,
    type: '',
  })

  useEffect(() => {
    if (!showDrawer) {
      setMotivationalStatus({ title: motivationTxt, type: '' })
      setMediicalStatus({ title: medicalTxt, type: '' })
    } else {
      const selectedTags = TagsArr.map((item) => {
        const selected = showDrawer && showDrawer?.feedback?.indexOf(item.title) > -1 ? true : false
        item.active = selected
        return item
      })
      setTags(selectedTags)
    }
  }, [showDrawer])

  const onMedical = (e: any) => {
    const index: number = e.key as number
    const selected: DropMenuItem = medicalDisqualification[index] as DropMenuItem

    // update the text in dropdown
    setMediicalStatus(selected)

    const now = new Date().getTime()
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        subjectId: showDrawer?.id,
        state: selected.type,
        subjectEvent: {
          id: now.toString(),
          eventData: selected.title,
          eventType: selected.type,
          createdBy: 'You',
          createdAt: now,
        },
      },
    })
  }

  const medicalMenu = (
    <Menu onClick={onMedical}>
      {medicalDisqualification.map((item, index) => {
        return (
          <Menu.Item key={index}>
            <p className="text-primary mb-0 text-capitalize">{item.title}</p>
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const onMotivation = (e: any) => {
    const index: number = e.key as number
    const selected: DropMenuItem = motivationDisqualification[index] as DropMenuItem

    setMotivationalStatus(selected)
    const now = new Date().getTime()
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        subjectId: showDrawer?.id,
        state: selected.type,
        subjectEvent: {
          id: now.toString(),
          eventData: selected.title,
          eventType: selected.type,
          createdBy: 'You',
          createdAt: now,
        },
      },
    })
  }

  const motivationMenu = (
    <Menu onClick={onMotivation}>
      {motivationDisqualification.map((item, index) => {
        return (
          <Menu.Item key={index}>
            {' '}
            <p className="text-primary mb-0 text-capitalize">{item.title}</p>
          </Menu.Item>
        )
      })}
    </Menu>
  )

  const tagClick = (index: number) => {
    tags[index].active = !tags[index].active
    const newTags = tags.concat([])
    setTags(newTags)
    dispatch({
      type: 'FEEDBACK',
      payload: {
        subjectId: showDrawer?.id,
        feedback: newTags
          .filter((item) => item.active)
          .map((item) => {
            return item.title
          }),
      },
    })
  }

  const [tags, setTags] = useState<{ title: string; active: boolean }[]>([{ title: '', active: false }])

  return (
    <div className="container h-100">
      <div
        className="row pb-4"
        style={{
          background: '#F0F0F0',
          borderBottom: '1px solid #C7C2FF',
          borderTop: '1px solid #C7C2FF',
        }}
      >
        <div className="col-12 mt-4">
          <h5 className="mb-1  text-primary d-flex">Choose disqualifying reason</h5>
          <p className="text-black">Please provide information to why this participant should be disqualified</p>
        </div>
        <div className="col-12">
          <StageDropdown menu={medicalMenu} initialValue={medicalStatus.title} background="bg-primary" />
        </div>
        <div className="col-12 my-2 d-flex justify-content-center"></div>
        <div className="col-12">
          <StageDropdown menu={motivationMenu} initialValue={motivationalStatus.title} background="bg-primary" />
        </div>
      </div>
      <div className="row pb-4" style={{ borderBottom: '1px solid #C7C2FF' }}>
        <div className="col-12 mt-4">
          <h5 className="mb-1  text-primary d-flex">Behavioral feedback</h5>
          <p className="text-black mb-2">Please provide better insignt on how the participants was to work with.</p>
        </div>

        <div className="col-12 d-flex flex-wrap">
          {tags.map((item, index) => {
            return <Tag key={index} click={tagClick} item={item} index={index} />
          })}
        </div>
      </div>
    </div>
  )
}
