import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { SubjectEvents, eventTypeArr } from '../../api/types'
import { ClockCircleOutlined } from '@ant-design/icons'
import { AniHeight } from '../Animation/AniHeight'
import { getDisplayEvent } from '../../utilities/utils'

type Props = {
  events: SubjectEvents[]
}

export const ContactHistory: React.FC<Props> = ({ events }) => {
  const animateLastEvent = events.length > 0 && events[0].createdAt > new Date().getTime() - 5000 ? true : false

  return (
    <div className="pt-4 px-3 d-flex flex-grow-1">
      <ClockCircleOutlined style={{ fontSize: '1.2rem' }} />
      <Scrollbars style={{ marginTop: '1rem', marginBottom: '-1rem', height: '100%' }}>
        {events.map((item, index) => {
          const d = new Date(item.createdAt)
          const day = `0${d.getDate()}`.slice(-2)
          const month = `0${d.getMonth() + 1}`.slice(-2)
          const fullDate = `${day}.${month}` //.${d.getFullYear()}

          const isStage = eventTypeArr.indexOf(item.eventType) > -1

          return (
            <AniHeight key={item.id} initialAnimation={animateLastEvent}>
              {isStage ? (
                <div className="d-flex no-select">
                  <p className="pl-2 mb-2 text-black font-weight-bold">{fullDate}</p>
                  <div className="pl-2">
                    <p className="mb-0 font-weight-bold text-black text-capitalize">
                      {getDisplayEvent(item.eventType).toLowerCase()}
                    </p>
                    {item.eventData && <p className="mb-0 text-grey"> {item.eventData}</p>}

                    <p className="mb-1 text-muted small text-grey">Update by {item.createdBy}</p>
                  </div>
                </div>
              ) : (
                <div className="d-flex no-select">
                  <p className="pl-2 mb-2 text-primary ">{fullDate}</p>
                  <div className="pl-2">
                    <p className="mb-0 font-weight-bold text-primary text-capitalize">
                      {getDisplayEvent(item.eventType).toLowerCase()}
                    </p>
                    {item.eventData && item.eventData !== 'tag' && <p className="mb-0 text-grey"> {item.eventData}</p>}
                    <p className="mb-1 text-muted small text-grey">Update by {item.createdBy}</p>
                  </div>
                </div>
              )}
            </AniHeight>
          )
        })}
      </Scrollbars>
    </div>
  )
}
