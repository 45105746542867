import React, { useReducer, useState } from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'

import { data } from '../../api/data'
import { pages, SingleParticipantWithState } from '../../api/types'
import { Container } from '../../components/Display/Container'
import { Header } from '../../components/Header/Header'
import { Navigation } from '../../components/Navigation'
import { AnimatedRoute } from '../../components/Navigators/AnimatedRoute'
import { SubjectDrawer } from '../../components/Drawer/SubjectDrawer'
import { subjectReducer } from '../../utilities/subjectReducer'
import { Banner } from '../Overview/Banner'
import { FollowUps } from '../Overview/FollowUps'
import { NewReferrals } from '../Overview/NewReferrals'
import { Participants } from '../Participants/Participants'
import { SubjectDetails } from '../../components/Drawer/SubjectDetails'
import { DisqulifyDrawer } from '../../components/Drawer/DisqulifyDrawer'
import { Disqualified } from '../Disqualified/Disqualified'
import { SearchBox } from '../../components/SearchBox/SearchBox'

type Props = {}

export const Main: React.FC<Props> = () => {
  const trial = { id: '666', name: 'A test trial' }

  const [showDrawer, setDrawerData] = useState<SingleParticipantWithState | null>(null)
  const [showDisqualify, setDisqulifyData] = useState<SingleParticipantWithState | null>(null)

  //const data = data?.trial?.subjects as SingleParticipantWithState
  const [state, dispatch] = useReducer(subjectReducer, [])

  if (!state.length && data) {
    dispatch({ type: 'INIT', payload: data?.trial?.subjects })
  }

  if (state.length > 0) {
    /*const user = data?.trial?.subjects[0]
    setTimeout(() => {
      setDrawerData(user as SingleParticipantWithState)
    }, 500)*/
  }

  return (
    <div className="container-fluid px-0 h-100" style={{ overflowX: 'hidden', overflowY: 'scroll' }}>
      <SubjectDrawer showDrawer={showDrawer} setDrawerData={setDrawerData} title="Participant details">
        <SubjectDetails setDisqulifyData={setDisqulifyData} showDrawer={showDrawer} dispatch={dispatch} />
        <SubjectDrawer showDrawer={showDisqualify} setDrawerData={setDisqulifyData} title="Disqualify">
          <DisqulifyDrawer showDrawer={showDrawer} setDrawerData={setDrawerData} dispatch={dispatch} />
        </SubjectDrawer>
      </SubjectDrawer>
      <Router>
        <Header trialName={trial?.name} />
        <Navigation pages={pages} />
        <AnimatedRoute>
          <Route exact path={`/${pages[0].path}`}>
            <Container>
              <SearchBox setDrawerData={setDrawerData} subjects={state} />
              <Banner />
              <NewReferrals
                dispatch={dispatch}
                setDisqulifyData={setDisqulifyData}
                setDrawerData={setDrawerData}
                subjects={state}
              />
              <FollowUps subjects={state} setDrawerData={setDrawerData} />
            </Container>
          </Route>
          <Route exact path={`/${pages[1].path}`}>
            <Container>
              <SearchBox setDrawerData={setDrawerData} subjects={state} />

              <Participants setDrawerData={setDrawerData} subjects={state} />
            </Container>
          </Route>
          <Route exact path={`/${pages[2].path}`}>
            <Container>
              <SearchBox setDrawerData={setDrawerData} subjects={state} />
              <Disqualified
                setDrawerData={setDrawerData}
                subjects={state}
                setDisqulifyData={setDisqulifyData}
              ></Disqualified>
            </Container>
          </Route>
          <Redirect to={`/${pages[0].path}`} />
        </AnimatedRoute>
      </Router>
    </div>
  )
}
