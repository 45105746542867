import React, { useRef } from 'react'
import { animated, useTransition } from 'react-spring'

type Props = {
  children?: React.ReactElement[]
  pageIndex: number
  endHandler?: Function
}

export const WizardWrapper: React.FC<Props> = ({ pageIndex, children }) => {
  const activePage = React.Children.toArray(children)[pageIndex]

  const oldIndex = useRef(0)
  const direction = oldIndex.current > pageIndex ? 200 : -200
  oldIndex.current = pageIndex

  const transitions = useTransition(activePage, pageIndex, {
    from: { opacity: 0, transform: `translate3d(${-direction}px,0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: `translate3d(${direction}px,0,0)` },
    config: { mass: 1, tension: 150, friction: 20 },

    //reset: true,
  })

  return (
    <div className=" h-100 w-100" style={{ top: 0, left: 0, position: 'relative' }}>
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div className="position-absolute w-100 h-100" key={key} style={{ ...props }}>
            {children}
          </animated.div>
        )
      })}
    </div>
  )
}
