import React from 'react'
import { NavLink } from 'react-router-dom'

import { Page } from '../api/types'

type Props = {
  pages: Page[]
}

export const Navigation: React.FC<Props> = ({ pages }) => {
  return (
    <div className="w-100 bg-primary d-flex justify-content-center mb-4">
      <div className="d-flex ">
        {pages.map((page, index) => {
          return (
            <NavLink
              key={index}
              to={{
                pathname: `/${page.path}`,
                state: { index: index },
              }}
              className="px-4 use-pointer no-select"
              style={{ opacity: 0.8 }}
              activeStyle={{ opacity: 1 }}
            >
              <p className="my-3 text-white font-weight-bold" style={{ fontSize: '.75rem', letterSpacing: 1.4 }}>
                {page.title.toUpperCase()}
              </p>
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}
