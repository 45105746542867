/* eslint-disable @typescript-eslint/camelcase */
import {
  getTrialDataQuery_trial_subjects,
  getTrialDataQuery_trial_subjects_events,
} from './__generated__/getTrialDataQuery'

const makeSlug = (slug: string): string => {
  const s = slug
    .toLowerCase()
    .split('_')
    .join('-')
  return s.split(' ').join('-')
}

const OVERVIEW = 'Overview'
const PARTICIPANTS = 'Participants'
const DISQUALIFIED = 'Disqualified'
export const FOLLOW_UP = 'Need follow up'
export const IN_PROGRESS = 'In Progress'

export const ONE_DAY = 24 * 60 * 60 * 1000

export type Page = {
  title: string
  path: string
}

export const TIME_LIMIT = 6

export const pages: Page[] = [
  {
    title: OVERVIEW,
    path: makeSlug(OVERVIEW),
  },
  {
    title: PARTICIPANTS,
    path: makeSlug(PARTICIPANTS),
  },
  {
    title: DISQUALIFIED,
    path: makeSlug(DISQUALIFIED),
  },
  /*{
    title: FOLLOW_UP,
    path: makeSlug(FOLLOW_UP),
  },*/
]

export enum Events {
  SIGNED_UP = 'SIGNED_UP',
  NOT_REACHED = 'NOT_REACHED',
  REVIEWING_INFORMATION = 'REVIEWING_INFORMATION',
  SCHEDULED_FOR_SCREENING = 'SCHEDULED_FOR_SCREENING',
  SCHEDULED_FOR_RANDOMISATION = 'SCHEDULED_FOR_RANDOMISATION',
  RANDOMISED = 'RANDOMISED',
  LEFT_TRIAL = 'LEFT_TRIAL',
  // status events
  WAITING = 'WAITING',
  NO_SHOW = 'NO_SHOW',
  RESCHEDULED = 'RESCHEDULED',
  FOLLOW_UP = 'FOLLOW_UP',
}

export const eventTypeArr = [
  Events.SIGNED_UP.toString(),
  //Events.NOT_REACHED,
  Events.REVIEWING_INFORMATION.toString(),
  Events.SCHEDULED_FOR_SCREENING.toString(),
  Events.SCHEDULED_FOR_RANDOMISATION.toString(),
  Events.RANDOMISED.toString(),
  Events.LEFT_TRIAL.toString(),
]

export type DropMenuItem = {
  type: EventType
  title: string
}

export const stageArr = [
  //{ type: Events.SIGNED_UP, title: 'Signed Up' },

  { type: Events.REVIEWING_INFORMATION, title: 'Reviewing information' },
  { type: Events.SCHEDULED_FOR_SCREENING, title: 'Scheduled for screening' },
  {
    type: Events.SCHEDULED_FOR_RANDOMISATION,
    title: 'Scheduled for randomisation',
  },
  { type: Events.RANDOMISED, title: 'Randomised' },
  //{ type: Events.LEFT_TRIAL, title: 'Left trial' },
]

export const statusArr = [
  { type: Events.NOT_REACHED, title: 'Not Reached' },
  { type: 'WAITING', title: 'Waiting for reply' },
  { type: 'NO_SHOW', title: 'No show' },
  { type: 'RESCHEDULED', title: 'Rescheduled' },
  { type: FOLLOW_UP, title: 'Follow up' },
]

export const medicalDisqualification = [
  { type: Events.LEFT_TRIAL, title: 'None' },
  { type: Events.LEFT_TRIAL, title: 'Misdiagnosis condition' },
  { type: Events.LEFT_TRIAL, title: 'Not Severe enough' },
  { type: Events.LEFT_TRIAL, title: 'Too severe' },
  { type: Events.LEFT_TRIAL, title: 'Body surface area' },
  { type: Events.LEFT_TRIAL, title: 'Age' },
  { type: Events.LEFT_TRIAL, title: 'Other exclusion criteria' },
]
export const motivationDisqualification = [
  { type: Events.LEFT_TRIAL, title: 'None' },
  { type: Events.LEFT_TRIAL, title: 'Lost to follow up' },
  { type: Events.LEFT_TRIAL, title: 'Too far to travel' },
  { type: Events.LEFT_TRIAL, title: 'Other commitments' },
  { type: Events.LEFT_TRIAL, title: 'Restrictive work schedule' },
  { type: Events.LEFT_TRIAL, title: 'No longer wants to participate' },
  { type: Events.LEFT_TRIAL, title: 'Dont want to stop current treatment' },
  { type: Events.LEFT_TRIAL, title: 'Subject withdrew consent' },
]

// typescript complains if we refer to a const
export type EventType =
  | Events.SIGNED_UP
  | Events.NOT_REACHED
  | Events.REVIEWING_INFORMATION
  | Events.SCHEDULED_FOR_SCREENING
  | Events.SCHEDULED_FOR_RANDOMISATION
  | Events.RANDOMISED
  | Events.LEFT_TRIAL

export const TagsArr = [
  { title: 'Motivated', active: false },
  { title: 'Positive', active: false },
  { title: 'Well informed', active: false },
  { title: 'Commited', active: false },
  { title: 'Flexible', active: false },
  { title: 'Good insight', active: false },
  { title: 'Responsive', active: false },
  { title: 'Friendly', active: false },
]

export const tabs = [
  {
    name: 'Reviewing information',
    path: makeSlug(Events.REVIEWING_INFORMATION),
    filterKey: Events.REVIEWING_INFORMATION,
  },
  {
    name: 'Scheduled for screening',
    path: makeSlug(Events.SCHEDULED_FOR_SCREENING),
    filterKey: Events.SCHEDULED_FOR_SCREENING,
  },

  {
    name: 'Scheduled for randomisation',
    path: makeSlug(Events.SCHEDULED_FOR_RANDOMISATION),
    filterKey: Events.SCHEDULED_FOR_RANDOMISATION,
  },
]

export type TabItem = {
  name: string
  path: string
  description: string
  imgSrc: string
  imgAlt: string
  filterKey: EventType
}

export interface SingleParticipantWithState extends getTrialDataQuery_trial_subjects {
  state?: EventType
  status?: EventType
  feedback: string[]
}

export type SingleParticipant = getTrialDataQuery_trial_subjects
export type SubjectEvents = getTrialDataQuery_trial_subjects_events
