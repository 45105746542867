import './styles/style.scss'
import './styles/size-debug.scss'

import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'

//
// debug indication of which breakpoint which is active
//

if (process.env.NODE_ENV !== 'production') {
  // test
  //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  import('./styles/size-debug.scss').then(() => {})
}

//setupSentry()

ReactDOM.render(<App />, document.getElementById('root'))
