import React, { memo } from 'react'
import { useSpring, animated } from 'react-spring'
import useResizeAware from 'react-resize-aware'

type Props = {
  children: React.ReactNode
  initialAnimation: Boolean
}

export const AniHeight: React.FC<Props> = memo(({ children, initialAnimation }) => {
  const [resizeListener, sizes] = useResizeAware()

  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 1, transform: 'translate3d(70px,0,0)' },
    to: {
      height: sizes.height ? sizes.height : 0,
      opacity: sizes.height ? 1 : 0,
      transform: `translate3d(0px,0,0)`,
    },
  })

  return (
    <animated.div
      className="overflow-hidden mb-2"
      style={{
        opacity: opacity as any,
        height: initialAnimation ? height : 'auto',
      }}
    >
      <animated.div style={{ transform }}>
        {resizeListener}
        {children}
      </animated.div>
    </animated.div>
  )
})
