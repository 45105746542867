import './attention-dot.scss'
import React from 'react'

type Props = {
  className?: string
  h?: number
  w?: number
}

export const AttentionDot: React.FC<Props> = ({ className = '', w = 20, h = 20 }) => {
  return (
    <div className={`attention-dot d-flex align-items-center ${className}`} style={{ width: w, height: h }}>
      <div style={{ border: '2px solid red' }}></div>
    </div>
  )
}
