/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24-05-2020.
 */
import React from 'react'
import { Events, SingleParticipantWithState } from '../../api/types'
import { DisqualifiedItem } from './DisqualifiedItem'

type Props = {
  subjects: SingleParticipantWithState[]
  setDrawerData: Function
  setDisqulifyData: Function
}

export const Disqualified: React.FC<Props> = ({ subjects, setDrawerData, setDisqulifyData }) => {
  const items = subjects.filter((item) => item.state === Events.LEFT_TRIAL)

  return (
    <div className="container mt-5">
      {/*<div className="row mt-3 " style={{}}>
        <div className="col-12 py-3 d-flex ">
          <div
            className="mr-2"
            style={{ marginTop: '.4rem', background: '#212628', width: 15, height: 1 }}
          ></div>
          <h6 className="font-weight-bold " style={{ color: '#212628', fontSize: '.85rem' }}>
            <em>Disqualified participants</em>
          </h6>
        </div>
      </div>*/}
      <div className="row mt-2 ">
        {items.map((item) => {
          return (
            <DisqualifiedItem
              key={item.id}
              setDrawerData={setDrawerData}
              setDisqulifyData={setDisqulifyData}
              subject={item}
            />
          )
        })}
      </div>
    </div>
  )
}
